<template>
  <div class="wrap">
    <!-- 竞卖信息 -->
    <div class="content">
      <Title name='竞卖信息'></Title>
      <el-alert
        :title="fromAutionInfo.checkNote"
        type="error"
        show-icon
        v-if="fromAutionInfo.checkState=='F'"
      >
      </el-alert>
      <div class="box" >
        <p><span>产品图片：</span><img :src="fromAutionInfo.picUrl" class="image"></p>
        <p><span>产品名称：</span>{{fromAutionInfo.prodName}}</p>
        <p><span>产品类型：</span>{{fromAutionInfo.name}}</p>
        <p><span>规格型号：</span>{{fromAutionInfo.prodModels}}</p>
        <p><span>数量：</span>{{fromAutionInfo.amount+fromAutionInfo.units}}</p>
        <p><span>起拍价：</span>{{fromAutionInfo.upPrice}}</p>
        <p><span>最低价：</span>{{fromAutionInfo.bottomPrice}}</p>
        <p><span>降价策略(元/分钟)：</span>{{fromAutionInfo.failTactics}}</p>
        <p><span>开始时间：</span>{{fromAutionInfo.startTime}}</p>
        <p><span>持续时间：</span>{{fromAutionInfo.continueTime}}</p>
        <p><span>发票类型：</span>{{fromAutionInfo.invoiceType}}</p>
        <p><span>付款方式：</span>{{fromAutionInfo.payMode}}</p>
        <p v-if="fromAutionInfo.payMode === '商业承兑汇票'&& fromAutionInfo.bankBillTerm != null "><span>承兑汇票期限：</span>{{fromAutionInfo.bankBillTerm}}个月</p>
        <p><span>订单模式：</span>{{fromAutionInfo.orderProcess}}</p>
      </div>

      <div class="explain">
<!--        <span>竞卖说明：</span><div class="html" v-html="fromAutionInfo.autionDetails"></div></div>-->
        <span>竞卖说明：</span><div class="html" v-html="fromAutionInfo.autionDetails"></div></div>
    </div>

    <!-- 成交信息 -->
    <div class="content" v-if="fromAutionInfo.clientName!=null && fromAutionInfo.clientName!=''">
      <Title name='成交信息'></Title>
      <div class="box">
        <p><span>竞卖成交企业：</span>{{fromAutionInfo.clientName}}</p>
        <p><span>联系人：</span>{{fromAutionInfo.users}}</p>
        <p><span>联系方式：</span>{{fromAutionInfo.tel}} <!-- <span class="see">查看详细信息</span> --></p>
        <p><span>成交时间：</span>{{fromAutionInfo.autionTime}}</p>
        <p><span>成交价：</span>{{fromAutionInfo.MaxAmount}}元</p>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../../component/Title';
import {
    myAutionInfoDetail
} from '../../api';


export default {

             created(){
                 this.initMyAutionInfoDetail();

    },
    data(){
      return {
        fromAutionInfo: {
          picUrl: '',//产品图片
          prodName: '',//产品名称
          name: '',//产品类型
          prodModels: '',//规格型号
          amount: '',//数量
          units: '',//单位
          upPrice: '',//起拍价
          bottomPrice: '',//最低价
          failTactics: '',//降价策略(元/分钟)
          startTime: '',//开始时间
          continueTime: '',//持续时间
          autionDetails: '',//竞卖说明
          clientName: '',//公司名称
          users: '',//用户名称
          tel: '',//用户手机号
          autionTime: '',//成交时间
          MaxAmount: '',//成交价格
          checkState: '', //审核状态
          checkNote: '',//通过或未通过原因
          orderProcess: '',//订单模式
          payMode: '', //付款方式
          bankBillTerm: '', //承兑汇票期限
          invoiceType:''//发票类型

        }
      }

       },

    methods:{
        //加载我的订单，详情
        initMyAutionInfoDetail(){
          myAutionInfoDetail({
            id: this.$route.query.id
          }).then(
            res=>{
            this.fromAutionInfo=res.data
              this.fromAutionInfo.payMode=='YHZZ' ? this.fromAutionInfo.payMode = '银行转账' : this.fromAutionInfo.payMode = '商业承兑汇票'
              this.fromAutionInfo.invoiceType=='0'? this.fromAutionInfo.invoiceType = '专用发票': this.fromAutionInfo.invoiceType = '普通发票'
              if(this.fromAutionInfo.orderProcess=='HK'){
                this.fromAutionInfo.orderProcess='先货后款';
              };
              if(this.fromAutionInfo.orderProcess=='KH'){
                this.fromAutionInfo.orderProcess='先款后货';
              }
            }
                  )



        }



             },

  components: {
    Title
  }
}
</script>

<style lang="less" scoped>
  @color1: #4DB1CD;

  .wrap {
    width: 100%;

    .content {
      background: #fff;
      margin-top: 18px;

      .box {
        padding: 20px 30px;
        display: flex;
        flex-wrap: wrap;

        p {
          display: flex;
          width: 48%;
          margin: 14px 0;
          font-size: 15px;

          span {
            color: #9C9C9C;

            &.see {
              color: @color1;
              margin-left: 8px;
              cursor: pointer;
            }
          }
          .image {
            width: 90px;
            height: 90px;
          }
        }
      }
      .explain {
        padding: 0 30px 40px 30px;
        font-size: 15px;
        display: flex;

        span {
          color: #9C9C9C;
        }
        .html {
          flex: 1;
        }
      }
    }
  }
</style>
