var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _c("p", [_vm._v(_vm._s(_vm.name))]),
    _vm.operate
      ? _c("div", { staticClass: "operate" }, [_vm._t("default")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }