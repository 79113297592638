<template>
  <div class="header">
    <p>{{ name }}</p>
    <div class="operate" v-if="operate">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'operate', 'height', 'lineHeight']
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  height: 30px;
  line-height: 42px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 6px 20px;
  border-bottom: 1px solid #f2f2f2;
  // font-weight:bold;
  font-size: 18px;
}
</style>
