var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("Title", { attrs: { name: "竞卖信息" } }),
        _vm.fromAutionInfo.checkState == "F"
          ? _c("el-alert", {
              attrs: {
                title: _vm.fromAutionInfo.checkNote,
                type: "error",
                "show-icon": "",
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "box" }, [
          _c("p", [
            _c("span", [_vm._v("产品图片：")]),
            _c("img", {
              staticClass: "image",
              attrs: { src: _vm.fromAutionInfo.picUrl },
            }),
          ]),
          _c("p", [
            _c("span", [_vm._v("产品名称：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.prodName)),
          ]),
          _c("p", [
            _c("span", [_vm._v("产品类型：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.name)),
          ]),
          _c("p", [
            _c("span", [_vm._v("规格型号：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.prodModels)),
          ]),
          _c("p", [
            _c("span", [_vm._v("数量：")]),
            _vm._v(
              _vm._s(_vm.fromAutionInfo.amount + _vm.fromAutionInfo.units)
            ),
          ]),
          _c("p", [
            _c("span", [_vm._v("起拍价：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.upPrice)),
          ]),
          _c("p", [
            _c("span", [_vm._v("最低价：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.bottomPrice)),
          ]),
          _c("p", [
            _c("span", [_vm._v("降价策略(元/分钟)：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.failTactics)),
          ]),
          _c("p", [
            _c("span", [_vm._v("开始时间：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.startTime)),
          ]),
          _c("p", [
            _c("span", [_vm._v("持续时间：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.continueTime)),
          ]),
          _c("p", [
            _c("span", [_vm._v("发票类型：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.invoiceType)),
          ]),
          _c("p", [
            _c("span", [_vm._v("付款方式：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.payMode)),
          ]),
          _vm.fromAutionInfo.payMode === "商业承兑汇票" &&
          _vm.fromAutionInfo.bankBillTerm != null
            ? _c("p", [
                _c("span", [_vm._v("承兑汇票期限：")]),
                _vm._v(_vm._s(_vm.fromAutionInfo.bankBillTerm) + "个月"),
              ])
            : _vm._e(),
          _c("p", [
            _c("span", [_vm._v("订单模式：")]),
            _vm._v(_vm._s(_vm.fromAutionInfo.orderProcess)),
          ]),
        ]),
        _c("div", { staticClass: "explain" }, [
          _c("span", [_vm._v("竞卖说明：")]),
          _c("div", {
            staticClass: "html",
            domProps: { innerHTML: _vm._s(_vm.fromAutionInfo.autionDetails) },
          }),
        ]),
      ],
      1
    ),
    _vm.fromAutionInfo.clientName != null && _vm.fromAutionInfo.clientName != ""
      ? _c(
          "div",
          { staticClass: "content" },
          [
            _c("Title", { attrs: { name: "成交信息" } }),
            _c("div", { staticClass: "box" }, [
              _c("p", [
                _c("span", [_vm._v("竞卖成交企业：")]),
                _vm._v(_vm._s(_vm.fromAutionInfo.clientName)),
              ]),
              _c("p", [
                _c("span", [_vm._v("联系人：")]),
                _vm._v(_vm._s(_vm.fromAutionInfo.users)),
              ]),
              _c("p", [
                _c("span", [_vm._v("联系方式：")]),
                _vm._v(_vm._s(_vm.fromAutionInfo.tel) + " "),
              ]),
              _c("p", [
                _c("span", [_vm._v("成交时间：")]),
                _vm._v(_vm._s(_vm.fromAutionInfo.autionTime)),
              ]),
              _c("p", [
                _c("span", [_vm._v("成交价：")]),
                _vm._v(_vm._s(_vm.fromAutionInfo.MaxAmount) + "元"),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }